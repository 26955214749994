import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Image from "~/components/image";
import Card from "~/components/styled/card";

const ActivityList = ({ activities }) => (
	<div className="grid grid-cols-1 md:grid-cols-2 gap-20 mb-20">
		{activities.map(({ node }) => {
			return (
				<Card key={node.id}>
					<Link to={`/activities/${node.slug}`}>
						{node.image && (
							<div className="">
								<Image
									className="rounded-t-md transform border-gray-200	 border-b"
									image={node.image}
									alt="Destination Image"
									loading="lazy"
								/>
							</div>
						)}
						{/* <Image
              alt="Category Image"
              className="rounded-t-md border-gray-200	 border-b"
              image={node.image}
            /> */}
						<p className="px-4 py-4 font-medium text-xl">{node.name}</p>
						<p className="px-4 pb-4 text-sm">{node.desc}</p>
					</Link>
				</Card>
			);
		})}
	</div>
);

ActivityList.propTypes = {
	activities: PropTypes.array,
};

export default ActivityList;
