import React from "react";
import { graphql } from "gatsby";
import Layout from "~/components/layout";
import PageHeading from "~/components/styled/page-heading";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import ActivityList from "~/components/activity-list";

const ActivitiesPage = ({ data }) => {
	const activities = data.allStrapiActivity.edges;
	//const seo = { title: "Activities" }
	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title="All Activities"
				description="Choose from our multitude of activities. There is something for everyone."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com/activities",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/activities",
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/activities",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com/activities",
				// 	},
				// ]}
			/>
			<PageHeading>All Activities</PageHeading>
			<ActivityList activities={activities} />
		</Layout>
	);
};

export const query = graphql`
	query ActivitiesPageQuery {
		allStrapiActivity(filter: { locale: { eq: "en" } }) {
			edges {
				node {
					name
					id
					slug
					desc
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.8)
							}
						}
					}
				}
			}
		}
	}
`;

export default ActivitiesPage;
